import React from 'react';
import './App.css';
import { Container } from 'react-bootstrap';
import { AuthProvider } from './Helpers/auth/auth.js';
import { ThemeProvider } from '@mui/material';
import { materialTheme } from "./Helpers/Style"
import { BrowserRouter as Router, Route , Routes} from "react-router-dom";
import { Messages, MessageProvider, MessageContext } from "./Helpers/messages/MessageRenderer.js";
import RequireAuth from './Helpers/auth/privateRoute';
import LoginPage from "./Components/Auth/login.js"
import ResetPassword from './Components/Pages/ResetPassword/ResetPassword.js'
import NavBar from './Components/Navbar/Navbar'
import Footer from './Components/Footer/footer.js'
import changePassword from './Components/Auth/changePassword';
import Home from "./Components/Pages/Home"
function App() {
  return (
    <div className="App">
      <ThemeProvider theme={materialTheme}>
        <AuthProvider>
          <MessageProvider>
            <Router>
              <MessageContext.Consumer>
                {({ messages, setMessages }) => {
                  return (<Messages messages={messages} setMessages={setMessages}></Messages>)
                }}
              </MessageContext.Consumer>
              <Container fluid={true} style={{ padding: 0 }}>
                <NavBar />
              </Container>
              {/* rgb(60 69 87) */}
              <Container fluid={true} style={{flex: 1, display: "flex", backgroundColor: "black", width: "100vw", padding : "0"}}>
                <Routes>
                  <Route exact path="/" element={<RequireAuth><Home /></RequireAuth>} />
                  <Route exact path="/login" Component={LoginPage} />
                  <Route exact path="/password/reset" Component={ResetPassword} />
                  <Route exact path="/password/change" Component={changePassword} />
                </Routes>
              </Container>
              <Container fluid={true} style={{ padding: 0 }}>
                <Footer />
              </Container>
            </Router>
          </MessageProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
