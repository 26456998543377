import React from 'react';
import { withRouter } from "../../Helpers/WithRouter";
import { withAuthManager } from '../../Helpers/auth/auth'
import { Row, Col } from 'react-bootstrap'
import Model from '../../Models/Model'
import AccessController from '../../Controller/AccessController'
import TrendComponent from './Home/TrendComponent/TrendComponent';
import ChartComponent from './Home/ChartComponent/ChartComponent';
import './home.css'
import TickerTableComponent from './Home/TickerTableComponent/TickerTableComponent';
import SentimentComponent from './Home/SentimentComponent/SentimentComponent';
import RSSComponent from './Home/RSSComponent/RSSComponent';

class Home extends React.Component { 
    constructor(props,context) {
        super(props,context)
        this.api = new Model()
        this.permissions = new AccessController()
    }
   
    render() {
        return (
           <div style={{padding: "0 1rem 2rem 1rem", width: "100%"}}>
            <Row className="" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <Col className="col-12 col-lg-4" >
                    <TrendComponent />
                </Col>
                <Col className="col-12 col-lg-4" >
                    <SentimentComponent />
                </Col>
                <Col className="col-12 col-lg-4" >
                    <RSSComponent />
                </Col>
            </Row>
            <Row className="">
                <Col className="col-12 col-lg-6 watchlist">
                    {this.props.user.user.isOAAMember == 1 && <TickerTableComponent />}
                </Col>
                <Col className="col-12 col-lg-6 charts" >
                    <ChartComponent />
                </Col>
            </Row>
            </div>
        )
    }
}
export default withAuthManager(withRouter(Home));