
import React from 'react';
import { Col } from 'react-bootstrap'
import FinancialData from '../../../../Models/FinancialData.js'

class TopTickerComponent extends React.Component { 
    constructor(props,context) {
        super(props,context)
        this.api = new FinancialData()
        this.api.GetDataSummary("SPY").then((resp) => {
            this.setState({
                spy: resp.value
            })
        })
        this.api.GetDataSummary("QQQ").then((resp) => {
            this.setState({
                qqq: resp.value
            })
        })
        this.api.GetDataSummary("^VIX",true).then((resp) => {
            this.setState({
                vix: resp.value
            })
        })
        this.state = {
            spy: null,
            qqq: null,
            vix: null,
        }
    }

    componentDidMount() {
    }

    render() {
        if (this.state.qqq == null || this.state.vix == null || this.state.spy == null) {
            return (
            <Col style={{textAlign: "center", fontWeight: "bold", fontSize: "1.5rem"}}>
                SPY - (-)
                QQQ - (-)
                VIX - (-)
            </Col>)
        }
        return (
            <Col style={{textAlign: "center", fontWeight: "bold", fontSize: "1.5rem"}}>
                SPY {this.state.spy.close.toFixed(2)} (<span style={{color: this.state.spy.difference < 0 ? "red" : "green"}}>{this.state.spy.differenceStr}</span>) <span style={{margin: "0 0.5rem"}}></span>
                QQQ {this.state.qqq.close.toFixed(2)} (<span style={{color: this.state.qqq.difference < 0 ? "red" : "green"}}>{this.state.qqq.differenceStr}</span>) <span style={{margin: "0 0.5rem"}}></span>
                VIX {this.state.vix.close.toFixed(2)} (<span style={{color: this.state.vix.difference < 0 ? "red" : "green"}}>{ this.state.vix.difference == null ? "-" : this.state.vix.differenceStr}</span>) <span style={{margin: "0 0.5rem"}}></span>
            </Col>
        )
    }
}
export default TopTickerComponent;