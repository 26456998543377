import Model from './Model'
class FinancialData {
    constructor() {
        this.model = new Model()
    }

    async GetDataSummary(symbol, useYahoo = false) {
        try {
            let url = useYahoo ? `/financial/data/summary/yahoo` : `/financial/data/summary`
            let resp = await this.model.getAsync(url, {symbol})
            return resp
        } catch (err) {
            return null
        }
    }
}
export default FinancialData;