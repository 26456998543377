
import React from 'react';
import { Row, Col } from 'react-bootstrap'
import './ChartComponent.css'
import Model from '../../../../Models/Model'
import TradingViewApi from '../../../../Models/TradingView'
class ChartComponent extends React.Component { 

    constructor(props,context) {
        super(props,context)
        this.tradingView = new TradingViewApi()
    }

    componentDidMount() {
        this.init()
        const s = document.createElement('script');
        const endpoint = new Model().apiEndPoint
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `
        window.tvwidget = new TradingView.widget({
            container: 'chartContainer',
            locale: 'en',
            library_path: 'charting_library/charting_library/',
            datafeed: new AlphaVantageDatafeed(),
            symbol: 'NASDAQ:AAPL',
            interval: '1D',
            enabled_features: ['study_templates'],
            left_toolbar: 'off',
            fullscreen: false,
            theme: 'dark',
            overrides: {
                "paneProperties.backgroundGradientStartColor": "black",
                "paneProperties.backgroundGradientEndColor": "black",
            },
            drawings_access: {
                tools: []
            },
            timeframe: "3M",
            time_frames: [
                { text: "5y", resolution: "1M", description: "5 Years" },
                { text: "1y", resolution: "1W", description: "1 Year" },
                { text: "6m", resolution: "1W", description: "6 Months" },
                { text: "1w", resolution: "1D", description: "1 Week" },
            ],
            disabled_features: ["left_toolbar","header_saveload"],
            debug: true,
            charts_storage_url: "${endpoint}",
            charts_storage_api_version: "1.1",
            custom_indicators_getter: function(PineJS) {
                return Promise.resolve([
                    {
                        name: 'Squeeze',
                        metainfo: {
                            id: 'Squeeze',
                            description: 'Squeeze',
                            shortDescription: 'Squeeze',
                            is_price_study: false,
                            isCustomIndicator: true,
                            inputs: [{
                                id: 'window',
                                name: 'Calculation Window (Days)',
                                type: 'integer',
                            }],
                            plots: [{
                                id: 'plot_1',
                                type: 'line',
                            },{
                                id: 'plot_2',
                                type: 'colorer',
                                target: 'plot_1',
                                palette: 'paletteId1',
                            },{
                                id: 'plot_3',
                                type: 'line',
                            },{
                                id: 'plot_4',
                                type: 'colorer',
                                target: 'plot_3',
                                palette: 'paletteId2',
                            }
                            ],
                            palettes: {
                                paletteId1: {
                                    valToIndex: {
                                        0: 0,
                                        1: 1,
                                        2: 2,
                                        3: 3,
                                    },
                                    colors: {
                                        0: {name: 'Up Rising',},
                                        1: {name: 'Up Falling',},
                                        2: {name: 'Down Rising',},
                                        3: {name: 'Down Falling',},
                                    },
                                },
                                paletteId2: {
                                    valToIndex: {
                                        0: 0,
                                        1: 1,
                                        2: 2,
                                        3: 3,
                                    },
                                    colors: {
                                        0: {name: 'High Comp',},
                                        1: {name: 'Normal Comp',},
                                        2: {name: 'Low Comp',},
                                        3: {name: 'No Squeeze',},
                                    },
                                },
                            },
                            defaults: {
                                palettes: {
                                    paletteId1: {
                                        colors: {
                                            0: {
                                                color: 'cyan',
                                                width: 3,
                                                style: 1,
                                            },
                                            1: {
                                                color: 'blue',
                                                width: 3,
                                                style: 1,
                                            },
                                            2: {
                                                color: 'yellow',
                                                width: 3,
                                                style: 1,
                                            },
                                            3: {
                                                color: 'red',
                                                width: 3,
                                                style: 1,
                                            },
                                        },
                                    },
                                    paletteId2: {
                                        colors: {
                                            0: {
                                                color: 'orange',
                                                width: 3,
                                                style: 1,
                                            },
                                            1: {
                                                color: 'red',
                                                width: 3,
                                                style: 1,
                                            },
                                            2: {
                                                color: 'yellow',
                                                width: 3,
                                                style: 1,
                                            },
                                            3: {
                                                color: 'green',
                                                width: 3,
                                                style: 1,
                                            },
                                        },
                                    },
                                },
                                styles:{
                                    plot_1: { plottype: 1,},
                                    plot_3: { plottype: 6,},
                                },
                                precision: 2,
                                inputs: {
                                    "window": 20
                                }
                            },
                            styles: {
                                plot_1: { title: 'Momentum', histogramBase: 0 },
                                plot_3: { title: 'Dots', histogramBase: 0 },
                            },
                        },
                        constructor: function() {
                            this.counter = 0
                            this.main = function(context, inputCallback) {
                                this._context = context;
                                this._input = inputCallback;

                                const window = this._input(0);
                                const dev_down = -2.0;
                                const dev_up = 2.0;
                                const factorhigh = 1.0;
                                const factormid = 1.5;
                                const factorlow = 2.0;

                                const curr_close = PineJS.Std.close(this._context)
                                const close = this._context.new_var(curr_close);
                                const high = this._context.new_var(PineJS.Std.high(this._context));
                                const low = this._context.new_var(PineJS.Std.low(this._context));
                                const tr = this._context.new_var(PineJS.Std.tr(true, this._context));
                                const sma = PineJS.Std.sma(close, window, this._context);
                                const avg_high_low = PineJS.Std.avg(PineJS.Std.highest(high, window, this._context), PineJS.Std.lowest(low, window, this._context))
                                const moving_mom = this._context.new_var(curr_close - PineJS.Std.avg(avg_high_low, sma))

                                const stddev = PineJS.Std.stdev(close, window, this._context);
                                const midline = sma
                                const lowerBand = midline + dev_down * stddev
                                const upperBand = midline + dev_up * stddev

                                const tr_sma = PineJS.Std.sma(tr, window, this._context)
                                const shifthigh = factorhigh * tr_sma;
                                const shiftMid = factormid * tr_sma;
                                const shiftlow = factorlow * tr_sma;

                                const UpperBandKCLow = midline + shiftlow;
                                const LowerBandKCLow = midline - shiftlow;
                                const UpperBandKCMid = midline + shiftMid;
                                const LowerBandKCMid = midline - shiftMid;
                                const UpperBandKCHigh = midline + shifthigh;
                                const LowerBandKCHigh = midline - shifthigh;
                                
                                const momo = PineJS.Std.linreg(moving_mom, window, 0)
                                const moving_momo = this._context.new_var(momo);

                                const pos = momo >= 0;
                                const up = momo >= PineJS.Std.nz(moving_momo.get(1),0);

                                var colorIndex = 0;
                                colorIndex = pos ? (up ? 0 : 1) : (up ? 2 : 3)
                                
                                const NoSqz = lowerBand < LowerBandKCLow || upperBand > UpperBandKCLow
                                const LowSqz = lowerBand >= LowerBandKCLow || upperBand <= UpperBandKCLow 
                                const MidSqz = lowerBand >= LowerBandKCMid || upperBand <= UpperBandKCMid
                                const HighSqz = lowerBand >= LowerBandKCHigh || upperBand <= UpperBandKCHigh

                                const sq_color = HighSqz ? 0 : MidSqz ? 1 : LowSqz ? 2 : 3
                                return [momo, colorIndex, 0, sq_color];
                            }
                        }
                    },{
                        name: 'CounterTrendArrow',
                        metainfo: {
                            id: 'CounterTrendArrow',
                            description: 'CounterTrendArrow',
                            shortDescription: 'CounterTrendArrow',
                            is_price_study: true,
                            isCustomIndicator: true,
                            linkedToSeries: true,
                            inputs: [{
                                id: 'lookback',
                                name: 'Lookback Window (Days)',
                                type: 'integer',
                            }],
                            plots: [{
                                id: 'plot_up',
                                type: 'shapes',
                            },{
                                id: 'plot_down',
                                type: 'shapes',
                            }],
                            defaults: {
                                styles:{
                                    plot_up : {
                                        plottype : 'shape_label_up',
                                        location: 'BelowBar',
                                        color: '#00FF00',
                                        visible: true,
                                    },
                                    plot_down : {
                                        plottype : 'shape_label_down',
                                        location: 'AboveBar',
                                        color: '#FF0000',
                                        visible: true,
                                    },
                                },
                                precision: 0,
                                inputs: {
                                    "lookback": 20
                                }
                            },
                            styles: {
                                plot_up : {
                                    plottype : 'shape_label_up',
                                    location: 'BelowBar',
                                    color: '#00FF00',
                                    visible: true,
                                },
                                plot_down : {
                                    plottype : 'shape_label_down',
                                    location: 'AboveBar',
                                    color: '#FF0000',
                                    visible: true,
                                },
                            },
                        },
                        constructor: function() {
                            this.counter = 0
                            this.latestLOHB = 0
                            this.closedAboveLOHB = 0
                            this.latestHOLB = 0
                            this.closedAboveHOLB = 0
                            this.main = function(context, inputCallback) {
                                this._context = context;
                                this._input = inputCallback;

                                const window = this._input(0);

                                const curr_close = PineJS.Std.close(this._context)
                                const close = this._context.new_var(curr_close);
                                const high = this._context.new_var(PineJS.Std.high(this._context));
                                const low = this._context.new_var(PineJS.Std.low(this._context));

                                const highOrLowBar = this._context.new_var(high);
                                const closedAboveHighOrLowBar = this._context.new_var(0);
                                
                                const lowbar = PineJS.Std.low(this._context) == PineJS.Std.lowest(low, window, this._context)
                                const previousClosedAboveHOLB = this.closedAboveHOLB
                                if (lowbar) {
                                    this.latestHOLB = PineJS.Std.low(this._context)
                                    this.closedAboveHOLB = 0
                                } else if (PineJS.Std.close(this._context) > this.latestHOLB) {
                                    this.closedAboveHOLB = 1
                                }
                                const buy = this.closedAboveHOLB == 1 && previousClosedAboveHOLB == 0;

                                const highbar = PineJS.Std.high(this._context) == PineJS.Std.highest(high, window, this._context)
                                const previousClosedAboveLOHB = this.closedAboveLOHB
                                if (highbar) {
                                    this.latestLOHB = PineJS.Std.low(this._context)
                                    this.closedAboveLOHB = 0
                                } else if (PineJS.Std.close(this._context) < this.latestLOHB) {
                                    this.closedAboveLOHB = 1
                                }
                                const sell = this.closedAboveLOHB == 1 && previousClosedAboveLOHB == 0;
                                
                                const up = buy ? 1 : NaN;
                                const down = sell ? 1 : NaN;
                                return [up,down];
                            }
                        }
                    },
                ]);
            },
        });
        `
        this.instance.appendChild(s);
        // const calendar = document.createElement('script');
        // calendar.type = 'text/javascript';
        // calendar.async = true;
        // calendar.src = 'https://s3.tradingview.com/external-embedding/embed-widget-events.js';
        // calendar.innerHTML = `
        // {
        //     "colorTheme": "light",
        //     "isTransparent": false,
        //     "width": "400",
        //     "height": "550",
        //     "locale": "en",
        //     "importanceFilter": "-1,0,1",
        //     "countryFilter": "ar,au,br,ca,cn,fr,de,in,id,it,jp,kr,mx,ru,sa,za,tr,gb,us,eu"
        // }
        // `
        // this.instance.appendChild(calendar)
    }

    async init() {
        const studytemplate = await this.tradingView.GetDefaultStudyTemplate()
        const chart = window.tvwidget
        chart.onChartReady(() => {
            // chart.chart().applyStudyTemplate(studytemplate);
        })
    }

    render() {
        
        return (
            <Row>
                <Col >
                    <div ref={el => (this.instance = el)} >
                        <div id="chartContainer" style={{display: "flex", justifyContent: "center"}}>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}
export default ChartComponent;