import React from 'react';
import { withRouter } from "../../Helpers/WithRouter";
import { withMessageManager } from '../../Helpers/messages/MessageRenderer.js'
import { Row, Col, Form } from 'react-bootstrap'
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import { Formik, getIn } from "formik";
import * as Yup from "yup";

import { authenticate, withAuthManager } from '../../Helpers/auth/auth.js'
import FullScreenModal from "../FullScreenModal/FullScreenModal.js"
import FullScreenSpinner from "../FullScreenSpinner/FullScreenSpinner.js"
import './login.css'
import UserAccount from '../../Models/UserAccount.js';

class LoginPage extends React.Component {
    constructor(props, context) {
        super()
        this.validationSchema = Yup.object().shape({
            username: Yup.string()
                .email("Username should be an email")
                .required("Please enter your username"),
            password: Yup.string().required("Please enter your password"), 
        })
        this.resetPWValidationSchema = Yup.object().shape({
            username: Yup.string()
                .email("Please enter a valid email")
                .required("Please enter your email address"),
        })
        this.accountApi = new UserAccount()
        this.state = {
            showForgotPasswordModal: false,
            errors : {}
        }
    }
    
    requestResetPassword = (username) => {
        this.accountApi.RequestPasswordReset(username, (res, err) => {
            if (res == null) {
                this.props.setMessages([{ type: "danger", message: err }])
            } else {
                this.setState({ showForgotPasswordModal: false });
                this.props.setMessages([{ type: "success", message: "An email has been sent to " + username }])
            }
        })
    }

    onHidePopupModal() {
        this.setState({ showForgotPasswordModal: false })
    }

    renderPopupModal() {
        var show = this.state.showForgotPasswordModal
        return (
            <FullScreenModal onCloseModal={() => { this.onHidePopupModal() }} show={show} size="lg">
                <div className="modalContainer">
                    <Row>
                        <Col>
                            <p className="fs-16" style={{ fontWeight: "600" }}>Enter your email below and we'll send you a link to reset your password.</p>
                        </Col>
                    </Row>
                    <Formik
                        initialValues={{
                            username: "",
                        }}
                        validationSchema={this.resetPWValidationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            var username = values.username
                            this.requestResetPassword(username)
                        }}>
                        {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                            <Form onSubmit={handleSubmit}>
                                <div>
                                    <TextField
                                        name="username"
                                        className="forgetpassfield"
                                        placeholder="Email address"
                                        fullWidth
                                        type="email"
                                        variant="outlined"
                                        value={values.username}
                                        onChange={handleChange}
                                        inputProps={this.propsForTextFieldInput}
                                        InputProps={this.propsForTextFieldInputProps}
                                        error={touched.username && errors.username}
                                        helperText={touched.username && errors.username}></TextField>
                                </div>
                                <div style={{ paddingTop: "15px" }}>
                                    <Button style={{ width: "100%" }} type="submit" variant="contained">Reset Password</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

            </FullScreenModal>
        )
    }

    render() {
        return (
            <Row style={{
                height: "auto",
                margin: "auto"
            }}>
                {this.renderPopupModal()}
            <Col style={{
                width: "50vw",
                margin: "auto",
                justifyContent: "center"
            }}> 
            <Formik
            initialValues={{
                username: "",
                password: ""
            }}
            validationSchema={this.validationSchema}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(true)
                authenticate(values.username, values.password, this.props.setUser, 
                    (success, userstore) => {
                        setSubmitting(false)
                        if (success) {
                            var path = undefined
                            if (this.props.router.location.state != null) {
                                path = this.props.router.location.state.referrer
                            }
                            if (path === undefined) {
                                this.props.router.navigate("/")
                            } else {
                                this.props.router.navigate(path)
                            }
                        } else {
                            this.setState({errors : {
                                auth : "Invalid username and password"
                            }})
                        }
                    } 
                )
            }}>
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) => 
                    <Form className="myForm loginform" onSubmit={handleSubmit} style={{textAlign : "start"}}>
                        {
                            isSubmitting && <FullScreenSpinner></FullScreenSpinner>
                        }
                        <Row style={{ marginBottom: "1rem"}}>
                            <Col>
                                <div>Username</div>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField type="text" placeholder="Username" variant="outlined" 
                                        initialvalue={values.username} name={`username`}                                       
                                        onChange={handleChange}
                                        error={(getIn(touched,`username`) && getIn(errors,`username`)) != undefined}
                                        helperText={(getIn(touched,`username`) && getIn(errors,`username`))}
                                    ></TextField>
                                </FormControl>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "1rem"}}>
                            <Col>
                            <div>Password</div>
                            <FormControl variant="outlined" fullWidth>
                                <TextField type="password" placeholder="Password" variant="outlined" 
                                    initialvalue={values.password} name={`password`}                                       
                                    onChange={handleChange}
                                    error={(getIn(touched,`password`) && getIn(errors,`password`)) != undefined}
                                    helperText={(getIn(touched,`password`) && getIn(errors,`password`))}
                                ></TextField>
                            </FormControl>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "1rem"}}>
                            <Col>
                                <a href="#" onClick={()=>{
                                    this.setState({showForgotPasswordModal: true})
                                }}>
                                    Forgot your password?
                                </a>
                            </Col>
                            <Col style={{textAlign : "end"}}>
                                <Button style={{
                                    fontSize: "1.5rem"
                                }} type="submit" variant="contained">
                                    Log In
                                </Button>
                            </Col>
                        </Row>
                        { this.state.errors["auth"] && 
                        <Row style={{ marginBottom: "1rem"}}>
                            <Col style={{color: "#d32f2f"}}>
                                {this.state.errors['auth']}
                            </Col>
                        </Row>
                        }
                    </Form>
                }
            </Formik>
            </Col>
            </Row>
        )
    }
}
export default withMessageManager(withAuthManager(withRouter(LoginPage)));