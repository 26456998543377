import Model from './Model'
class Screener {
    constructor() {
        this.model = new Model()
    }

    async GetStudyTemplate(name) {
        try {
            let resp = await this.model.getAsync(`/1.1/study_templates?client=0&user=0`, {template: name})
            return JSON.parse(resp.data.content)
        } catch (err) {
            return null
        }
    }
    async GetDefaultStudyTemplate() {
       return await this.GetStudyTemplate("default")
    }
}
export default Screener;