import React from 'react';
import { withRouter } from "../../Helpers/WithRouter";
import { Row, Col, Form } from 'react-bootstrap'
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import { Formik, getIn } from "formik";
import * as Yup from "yup";

import { logout, withAuthManager } from '../../Helpers/auth/auth.js'
import { withMessageManager } from '../../Helpers/messages/MessageRenderer.js'
import FullScreenSpinner from "../FullScreenSpinner/FullScreenSpinner.js"
import './login.css'
import UserAccount from '../../Models/UserAccount.js';

class ChangePasswordPage extends React.Component {
    constructor(props, context) {
        super()
        this.validationSchema = Yup.object().shape({
            password: Yup.string().required("Please enter your password"), 
            newPassword: Yup.string().required("Please enter your new password"), 
            confirmNewPassword: Yup.string()
                .required("Please confirm your new password")
                .test("passwords must match","The password confirmation does not match the new password.", function (value) {
                    return value == this.parent.newPassword
                }), 
        })
        this.accountApi = new UserAccount()
        this.state = {
            errors : {}
        }
    }

    render() {
        return (
            <Row style={{
                height: "auto",
                margin: "auto"
            }}>
            <Col style={{
                width: "50vw",
                margin: "auto",
                justifyContent: "center"
            }}> 
            <Formik
            initialValues={{
                username: "",
                password: "",
                newPassword: "",
                confirmNewPassword: ""
            }}
            validationSchema={this.validationSchema}
            onSubmit={async (values, {setSubmitting}) => {
                setSubmitting(true)
                let success = await new UserAccount().ChangePassword(this.props.user.user.username, values.password, values.newPassword)
                setSubmitting(false)
                if (!success) {
                    this.props.setMessages([{
                        message: "Failed to update password, please check if your password is correct or try again later",
                        type: "danger"
                    }])
                } else {
                    this.props.setMessages([{
                        message: "Your password has been updated, please log in with the new password",
                        type: "info"
                    }])
                    this.props.router.navigate("/login")
                    logout(this.props.setUser)
                }
            }}>
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) => 
                    <Form className="loginform" onSubmit={handleSubmit} style={{textAlign : "start"}}>
                        {
                            isSubmitting && <FullScreenSpinner></FullScreenSpinner>
                        }
                        <Row style={{ marginBottom: "1rem"}}>
                            <Col>
                                <div>Username</div>
                                <FormControl variant="outlined" fullWidth>
                                <TextField type="username" placeholder="Username" variant="outlined" 
                                    initialvalue={this.props.user.user.username} name={`username`}                                       
                                    error={(getIn(touched,`username`) && getIn(errors,`username`)) != undefined}
                                    helperText={(getIn(touched,`username`) && getIn(errors,`username`))}
                                    value={this.props.user.user.username}
                                    disabled
                                ></TextField>
                            </FormControl>
                                
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "1rem"}}>
                            <Col>
                            <div>Password</div>
                            <FormControl variant="outlined" fullWidth>
                                <TextField type="password" placeholder="Password" variant="outlined" 
                                    initialvalue={values.password} name={`password`}                                       
                                    onChange={handleChange}
                                    error={(getIn(touched,`password`) && getIn(errors,`password`)) != undefined}
                                    helperText={(getIn(touched,`password`) && getIn(errors,`password`))}
                                ></TextField>
                            </FormControl>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "1rem"}}>
                            <Col>
                            <div>New Password</div>
                            <FormControl variant="outlined" fullWidth>
                                <TextField type="password" placeholder="New Password" variant="outlined" 
                                    initialvalue={values.newPassword} name={`newPassword`}                                       
                                    onChange={handleChange}
                                    error={(getIn(touched,`newPassword`) && getIn(errors,`newPassword`)) != undefined}
                                    helperText={(getIn(touched,`newPassword`) && getIn(errors,`newPassword`))}
                                ></TextField>
                            </FormControl>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "1rem"}}>
                            <Col>
                            <div>Confirm New Password</div>
                            <FormControl variant="outlined" fullWidth>
                                <TextField type="password" placeholder="Confirm New Password" variant="outlined" 
                                    initialvalue={values.confirmNewPassword} name={`confirmNewPassword`}                                       
                                    onChange={handleChange}
                                    error={(getIn(touched,`confirmNewPassword`) && getIn(errors,`confirmNewPassword`)) != undefined}
                                    helperText={(getIn(touched,`confirmNewPassword`) && getIn(errors,`confirmNewPassword`))}
                                ></TextField>
                            </FormControl>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "1rem"}}>
                            <Col style={{textAlign : "end"}}>
                                <Button type="submit" style={{
                                    fontSize: "1.5rem"
                                }} variant="contained">
                                    Change Password
                                </Button>
                            </Col>
                        </Row>
                        { this.state.errors["auth"] && 
                        <Row style={{ marginBottom: "1rem"}}>
                            <Col style={{color: "#d32f2f"}}>
                                {this.state.errors['auth']}
                            </Col>
                        </Row>
                        }
                    </Form>
                }
            </Formik>
            </Col>
            </Row>
        )
    }
}
export default withMessageManager(withAuthManager(withRouter(ChangePasswordPage)));