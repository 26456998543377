import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from "./auth.js";
function RequireAuth({ children }) {
    const user = useAuth();
    if (user.user.isAuthenticated == undefined) {
        return <></>
    }
    if (user.user.isAuthenticated !== undefined && user.user.isAuthenticated) {
        return children;
    } else {
        return <Navigate to="/login" state={{ referrer: window.location.pathname }}/>
    }
}

export default RequireAuth;