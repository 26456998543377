import axios from "axios"
axios.defaults.withCredentials = true
class Model {
    constructor() {
        this.apiEndPoint = process.env.NODE_ENV == "development" ?  "http://localhost:4001" : "https://dashboard.backend.taooftrading.com"
    }

    async getAsync(path, params) {
        const response = await axios.get(
            this.apiEndPoint + path, 
            { 
                params : params ,
                withCredentials : true
            })
        if (response.error == "Token has expired, please re log in") {
            window.location = "/login"
            return
        }
        return response.data
    }

    async deleteAsync(path, data) {
        const response = await axios.delete(
            this.apiEndPoint + path, 
            { 
               ...data
            })
        if (response.error == "Token has expired, please re log in") {
            window.location = "/login"
            return
        }
        return response.data
    }

    async patchAsync(path, data) {
        const response = await axios.patch(
            this.apiEndPoint + path, 
            { 
                ...data
            })
        if (response.error == "Token has expired, please re log in") {
            window.location = "/login"
            return
        }
        return response.data
    }
    
    async post(path, body) {
        const response = await axios.post(
            this.apiEndPoint + path,
            body,
            {
                headers: {
                    'Content-Type' : 'application/json',
                },
                withCredentials : true
            })
        if (response !== null) {
            if (response.error == "Token has expired, please re log in") {
                window.location = "/login"
                return
            }
        }
        return response.data
    }

    postReq(path, body, callback){
        axios.post(
            this.apiEndPoint + path,
            body,
            {
                headers: {
                    'Content-Type' : 'application/json',
                },
                withCredentials : true
            })
        .then(function (response) {
            if (response !== null) {
                if (response.error == "Token has expired, please re log in") {
                    window.location = "/login"
                    return
                }
                callback(response.data, null)
                return
            }
        })
        .catch(function (error) {
            console.log(error)
            callback(null, error)
            return
        });
    }
    postArrBuffer(path,body, callback) {
        axios.post(
            this.apiEndPoint + path,
            body,
            {
                headers: {
                    'Content-Type' : 'application/json',
                },
                withCredentials : true,
                responseType : 'arraybuffer'
            })
        .then(function (response) {
            if (response !== null) {
                if (response.error == "Token has expired, please re log in") {
                    window.location = "/login"
                    return
                }
                callback(response.data, null)
                return
            }
        })
        .catch(function (error) {
            console.log(error)
            callback(null, error)
            return
        });
    }
}
export default Model;
