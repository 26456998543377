import Model from './Model'
class Screener {
    constructor() {
        this.model = new Model()
    }

    async GetScreener(variant) {
        try {
            let resp = await this.model.getAsync(`/screener`, {variant})
            return resp.value
        } catch (err) {
            return null
        }
    }
}
export default Screener;