import { createTheme } from '@mui/material/styles';
const textColor = {
  light: "#282c34",
  main: "#282c34",
  dark: "#282c34"
}
const materialTheme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "#eee", 
                }
            },
        },
    },
    overrides: {
        MuiPickersToolbar: {
        toolbar: {
            backgroundColor: "#27bdbe",
        },
        },
        MuiPickersCalendarHeader: {
        switchHeader: {
            // backgroundColor: lightBlue.A200,
            // color: "white",
        },
        },
        MuiPickersDay: {
        daySelected: {
            backgroundColor: "#27bdbe",
        },
        },
        MuiPickersModal: {
        dialogAction: {
            color: "#27bdbe",
        },
        },
    },
});
export {materialTheme}