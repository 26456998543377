
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RSS from '../../../../Models/RSS.js'
import { Spinner } from 'react-activity';
var XMLParser = require('react-xml-parser');

class RSSComponent extends React.Component { 
    constructor(props) {
        super(props)
        this.rss = new RSS();
        this.state = {
            feeds: [],
            loading: true
        }
    }

    componentDidMount() {
        this.loadFeed()
    }

    parseString(input) {
        return input
            .replaceAll("%20", " ")
            .replaceAll(">","")
            .replaceAll("[&#8230; ]","...")
    }

    async loadFeed() {
        this.setState({loading: true})
        let url = process.env.NODE_ENV == "development" ? "http://localhost:4001/rss/https://posts.taooftrading.com/feed/": "https://dashboard.backend.taooftrading.com/rss/https://posts.taooftrading.com/feed/"
        let feeds = await this.rss.GetFeed(url)
        if (feeds == null) {
            return
        }
        let xml = new XMLParser().parseFromString(feeds);  
        let items = xml.getElementsByTagName('item')
        var parsedItems = []
        var titleHash = {}
        items.map((item) => {
            let titleArr = item.getElementsByTagName('title')
            let linkArr = item.getElementsByTagName('link')
            let descripArr = item.getElementsByTagName('description')
            if (titleArr != null && titleArr.length > 0 &&
                linkArr != null && linkArr.length > 0 &&
                descripArr != null && descripArr.length > 0 &&
                titleArr.length == linkArr.length &&
                linkArr.length == descripArr.length
            ) {
                for (let i = 0; i < titleArr.length; i++) {
                    if (titleHash[titleArr[i].value] == true) {
                        continue
                    }
                    parsedItems.push({
                        title: this.parseString(titleArr[i].value),
                        link: linkArr[i].value,
                        description: this.parseString(descripArr[i].value),
                    })
                    titleHash[titleArr[i].value] = true
                }
            }
        })
        this.setState({feeds : parsedItems, loading: false})
    }

    render() {
        return (
            <div style={{ 
                backgroundColor: '#343434', 
                borderRadius: "1rem",
                padding: "1rem",
                height: "100%"
            }}>
                
                {   this.state.loading && <div style={{display: "flex", height: "100%", justifyContent: "center"}}>
                    <Spinner style={{
                            margin: "auto 1rem",
                    }}/>
                </div>
                }
                {
                    !this.state.loading && 
                    <div style={{
                        position: "relative",
                        height: "100%"
                    }}>
                        <div style={{
                            overflowY: "scroll",
                            position: "absolute",
                            top: 0,
                            right: 0, 
                            left: 0,
                            bottom: 0,
                        }}>
                            {this.state.feeds.map((feed) => {
                                return (
                                <div style={{textAlign: "left", borderBottom: "0.1rem solid grey", marginBottom: "1rem", paddingBottom: "1rem"}}>
                                    <div style={{fontWeight: "bold", fontSize: "1.2rem"}} dangerouslySetInnerHTML={{ __html: feed.title }}></div>
                                    <br />
                                    <div style={{fontSize: "1.2rem"}} dangerouslySetInnerHTML={{ __html: feed.description }}></div>
                                    <a target="_blank" href={feed.link}>Read more ... </a>
                                    <br />
                                </div>)
                            })}
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default RSSComponent;