import Model from './Model'
class TrendData {
    constructor() {
        this.model = new Model()
    }

    async GetSentiment() {
        try {
            let resp = await this.model.getAsync(`/sentiment/`, {})
            return resp
        } catch (err) {
            return null
        }
    }

    async GetTrendScore() {
        try {
            let resp = await this.model.getAsync(`/financial/trend?symbol=SPY`, {})
            return resp
        } catch (err) {
            return null
        }
    }


    async GetZScore() {
        try {
            let resp = await this.model.getAsync(`/financial/zscore?symbol=SPY`, {})
            return resp
        } catch (err) {
            return null
        }
    }

    async GetATR() {
        try {
            let resp = await this.model.getAsync(`/financial/atr?symbol=SPY`, {})
            return resp
        } catch (err) {
            return null
        }
    }
}
export default TrendData;