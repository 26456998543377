import Model from './Model'
class Watchlist {
    constructor() {
        this.model = new Model()
    }

    async GetWatchlist() {
        try {
            let resp = await this.model.getAsync(`/watchlist`, {})
            return resp.value
        } catch (err) {
            return null
        }
    }
    async GetCurrated() {
        try {
            let resp = await this.model.getAsync(`/watchlist/currated`, {})
            return resp.value
        } catch (err) {
            return null
        }
    }
    async GetDefault() {
        try {
            let resp = await this.model.getAsync(`/watchlist/default`, {})
            return resp.value
        } catch (err) {
            return null
        }
    }
    async RemoveFromWatchlist(symbols) {
        try {
            let resp = await this.model.patchAsync(`/watchlist/remove`, {symbols})
            return resp.value
        } catch (err) {
            return null
        }
    }
    async AddToWatchlist(symbols) {
        try {
            let resp = await this.model.patchAsync(`/watchlist/add`, {symbols})
            return resp.value
        } catch (err) {
            return null
        }
    }
}
export default Watchlist;