
import React from 'react';
import { Row, Col } from 'react-bootstrap'

class GaugeComponent extends React.Component { 
    radius = 100

    constructor(props,context) {
        super(props,context)
    }

    render() {
        let diameter = 2 * this.radius
        let height = this.radius + 5
        let max = this.props.max
        let min = this.props.min
        let data = Math.min(Math.max(min,this.props.data), max)
        let teta = (data - min) / (max - min) * 180
        return (
            <Row>
                <Col >
                    <img 
                        style={{ width: "100%"}}
                        src={require('../../../../Assets/gauge/gauge.png')}
                    ></img>
                    <div style={{
                        position: "absolute",
                        left: 15,
                        right: 15,
                        bottom: 0,
                        top: 0,
                    }}>
                        <svg  xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 " + diameter + " " + height}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        >
                            <g className="needle">
                                <polygon 
                                points={this.radius+"," + (this.radius - 5) + " " + this.radius+"," + (this.radius + 5) + ` 5,` + (this.radius)} 
                                fill={'#007bbd'}
                                transform={"rotate( "+teta+" "+this.radius+" " + this.radius + ")"}/>
                                <circle cx={this.radius} cy={this.radius} r="5" fill="#007bbd"></circle>
                                <circle cx={this.radius} cy={this.radius} r="3" fill="white"></circle>
                            </g>
                        </svg>
                    </div>
                </Col>
            </Row>
        )
    }
}
export default GaugeComponent;