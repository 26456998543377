import React from 'react';
import { withRouter } from "../../Helpers/WithRouter";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { logout, withAuthManager } from '../../Helpers/auth/auth.js';
import './Navbar.css'
import TopTickerComponent from "../Pages/Home/TopTickerComponent/TopTickerComponent"
import { NavDropdown } from 'react-bootstrap';
class Home extends React.Component {
    render() {
        return (
            <>
                <Navbar className="topHeader" expand="lg" style={{ backgroundColor: '#343434' }}>
                    <Navbar.Brand style={{ color: "white", fontWeight: "bold", flex: "0.3"}}>
                        <p className="logoText" style={{ margin: "auto 0", textAlign: "start" }}>
                            <img src={require("../../Assets/Icons/TOTLogo.png")} 
                                style={{ maxWidth: "300px", minWidth: "100px", width: "100%", paddingLeft: "3%", paddingRight: "1%" }} alt=""
                            />
                        </p>
                    </Navbar.Brand>
                    <Navbar.Brand style={{ color: "white", flexGrow: 1}}>
                        {this.props.user && this.props.user.isAuthenticated && <TopTickerComponent />}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{backgroundColor: "white"}}/>
                    <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav" style={{flexGrow: "inherit"}}>
                        <Nav className="justify-content-end">
                            {
                                this.props.user && this.props.user.isAuthenticated ? 
                                <NavDropdown className="profiledropdown" title="Profile">
                                    <NavDropdown.Item className='headertext' onClick={() => {
                                        this.props.router.navigate('/password/change')
                                    }}>
                                        Change Password
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className='headertext' onClick={() => {
                                        logout(this.props.setUser)
                                        this.props.router.navigate('/login')
                                    }}>
                                        Log Out
                                    </NavDropdown.Item>
                                </NavDropdown>
                                :
                                <Navbar.Text className="nav-text login-text clickable" onClick={() => {
                                    this.props.router.navigate("/login")
                                }}>
                                LOG IN
                                </Navbar.Text> 
                            }
                        </Nav>
                    </Navbar.Collapse>
</Navbar>
            </>
        )
    }
}
export default withAuthManager(withRouter(Home));
