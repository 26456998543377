
import React from 'react';
import Watchlist from '../../../../Models/Watchlist.js'
import Screener from '../../../../Models/Screener.js'
import { Row, Col } from 'react-bootstrap'
import { DataGrid } from '@mui/x-data-grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import WATCHLISTS from './WatchlistTypes.js';
import SCREENERS from './ScreenerTypes.js';
import './TickerTable.css'
import { Spinner } from 'react-activity';
import { Button } from '@mui/material';

const parseCompactedBackToNumber = (x) => {
    let base = parseFloat(x)
    if ( x.toLowerCase( ).match( /k/ ) ) {
        return Math.round( base * 1000 );
    } else if ( x.toLowerCase( ).match( /m/ ) ) {
        return Math.round( base * 1000000 );
    } else if ( x.toLowerCase( ).match( /b/ ) ) {
        return Math.round( base * 1000000000 );
    } else if ( x.toLowerCase( ).match( /t/ )) {
        return Math.round( base * 1000000000000 )
    }
}
const formattedNumberComparator = (a,b) => {
    if (a == "-" ) {
        return -1
    }
    if (b == "-" ) {
        return 1
    }
    return parseCompactedBackToNumber(a) > parseCompactedBackToNumber(b) ? 1 : -1
}
const percentageComparator = (a,b) => {
    let left = parseFloat(a)
    let right = parseFloat(b)
    return left > right ? 1 : -1
}

const columns = [
    { field: 'id', headerName: 'Symbol', type: 'string' },
    { field: 'close', headerName: 'Price', type: 'number' },
    { field: 'differenceStr', headerName: '% Change', sortComparator: percentageComparator},
    { field: 'ytdStr', headerName: '%YTD', sortComparator: percentageComparator },
    { field: 'marketCap', headerName: 'Market Cap', sortComparator: formattedNumberComparator },
    { field: 'volume',headerName: 'Volume', sortComparator: formattedNumberComparator },
    { field: 'relativePerformance', headerName: 'Rel. Perf.', type: "string", sortComparator: percentageComparator },
];
 
class TickerTableComponent extends React.Component { 
    constructor(props,context) {
        super(props,context)
        this.api = new Watchlist()
        this.screenerApi = new Screener()
        this.state = {
            tickerListView: WATCHLISTS.DEFAULT,
            rows : null,
            loading: true,
            selectedSymbols: [],
        }
    }

    componentDidMount() {
        this.loadWatchlist()
    }

    async loadWatchlist() {
        this.setState({ loading: true})
        var watchlist = null
        switch (this.state.tickerListView) {
            case WATCHLISTS.CURRATED: 
                watchlist = await this.api.GetCurrated()
                break
            case WATCHLISTS.MYWATCHLIST: 
                watchlist = await this.api.GetWatchlist()
                break
            case WATCHLISTS.DEFAULT: 
                watchlist = await this.api.GetDefault()
                break
            case SCREENERS.BOUNCEBEAR:
                watchlist = await this.screenerApi.GetScreener(SCREENERS.BOUNCEBEAR)
                break
            case SCREENERS.BOUNCEBULL:
                watchlist = await this.screenerApi.GetScreener(SCREENERS.BOUNCEBULL)
                break
            case SCREENERS.COUNTERTRENDLONG:
                watchlist = await this.screenerApi.GetScreener(SCREENERS.COUNTERTRENDLONG)
                break
            case SCREENERS.COUNTERTRENDSHORT:
                watchlist = await this.screenerApi.GetScreener(SCREENERS.COUNTERTRENDSHORT)
                break
            case SCREENERS.SQUEEZEBASIC:
                watchlist = await this.screenerApi.GetScreener(SCREENERS.SQUEEZEBASIC)
                break
            case SCREENERS.SQUEEZEBEAR:
                watchlist = await this.screenerApi.GetScreener(SCREENERS.SQUEEZEBEAR)
                break
            case SCREENERS.SQUEEZEBULL:
                watchlist = await this.screenerApi.GetScreener(SCREENERS.SQUEEZEBULL)
                break
            default:
                watchlist = await this.api.GetDefault()
                break
        }
        if (watchlist != null && watchlist.symbols.length > 0) {
            this.parseWatchlist(watchlist)
        } else {
            this.setState({rows : [], loading: false})
        }
    }

    parseWatchlist(watchlist) {
        let formatter = Intl.NumberFormat('en', { notation: 'compact' });
        let rows = watchlist.symbols.map((symbol) => {
            return { 
                id: symbol.symbol,
                ticker: symbol.ticker,
                close: symbol.close, 
                differenceStr: symbol.differenceStr, 
                ytdStr: symbol.ytdStr, 
                marketCap: symbol.marketCap != null ? formatter.format(symbol.marketCap) : '-', 
                volume: formatter.format(symbol.volume), 
                relativePerformance: symbol.relativePerformanceStr
            }
        })
        this.setState({
            rows,
            loading: false
        })
    }
    
    render() {
        return (
            <>
            <Row className="hideOnDesktop">
                <Col style={{fontSize : "1.5rem", fontWeight: "bold"}}>
                    Watchlists
                </Col>
            </Row>
            <Row >
                <Col style={{textAlign : "right", paddingBottom: "1rem"}}>
                    <div style={{display : "flex"}}>
                        <Select
                            className="watchlistdropdownbtn" 
                            style={{marginLeft : "auto", backgroundColor: "rgb(238, 238, 238)"}}
                            disabled={this.state.loading}
                            value={this.state.tickerListView}
                            onChange={(event) => {
                                this.setState({
                                    loading: true,
                                    tickerListView: event.target.value,
                                    selectedSymbols: []
                                }, () => {
                                    this.loadWatchlist()
                                });
                            }}
                            autoWidth
                        >
                            <MenuItem className="watchlistdropdown" value={WATCHLISTS.DEFAULT}>Default</MenuItem>
                            <MenuItem className="watchlistdropdown" value={WATCHLISTS.MYWATCHLIST}>My Watchlist</MenuItem>
                            <MenuItem className="watchlistdropdown" value={WATCHLISTS.CURRATED}>Simon's Watchlist</MenuItem>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem className="watchlistdropdown" value={SCREENERS.BOUNCEBEAR}>Bounce (Bearish)</MenuItem>
                            <MenuItem className="watchlistdropdown" value={SCREENERS.BOUNCEBULL}>Bounce (Bullish)</MenuItem>
                            <MenuItem className="watchlistdropdown" value={SCREENERS.SQUEEZEBASIC}>Squeeze (Neutral)</MenuItem>
                            <MenuItem className="watchlistdropdown" value={SCREENERS.SQUEEZEBEAR}>Squeeze (Bearish)</MenuItem>
                            <MenuItem className="watchlistdropdown" value={SCREENERS.SQUEEZEBULL}>Squeeze (Bullish)</MenuItem>
                            <MenuItem className="watchlistdropdown" value={SCREENERS.COUNTERTRENDSHORT}>Counter Trend (Short)</MenuItem>
                            <MenuItem className="watchlistdropdown" value={SCREENERS.COUNTERTRENDLONG}>Counter Trend (Long)</MenuItem>
                        </Select>
                        { 
                            this.state.loading && <Spinner style={{
                                margin: "auto 1rem",
                            }}/>
                        }
                    </div>
                </Col>
            </Row>
            { this.state.rows != null && ( this.state.rows.length > 0 ? 
                <Row>
                    <Col >
                        {this.state.loading && <div style={{
                            position: "absolute",
                            backgroundColor: "rgba(256,256,256,0.75)",
                            width: "100%",
                            height: "100%",
                            zIndex: 999
                        }}
                        >
                            <div style={{
                                display: "flex",
                                color: "black",
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                Loading New Data ... <Spinner />
                            </div>
                        </div>}
                        <DataGrid
                            className="tickertable"
                            rows={this.state.rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 20]}
                            checkboxSelection
                            rowSelectionModel={this.state.selectedSymbols}
                            onRowSelectionModelChange={(ids) => {
                                let selectedRow = this.state.rows.find(row => row.id == ids[ids.length-1])
                                let chart = window.tvwidget
                                if (selectedRow != null) {
                                    chart.setSymbol(selectedRow.ticker,"D")
                                }
                                if (this.state.loading) { return }
                                this.setState({selectedSymbols : ids})
                            }}
                        />
                    </Col>
                </Row>: 
                <div style={{fontSize: "1.8rem", height: '100%', display: 'flex'}}>
                    <div style={{margin: '5rem auto'}}>
                        {Object.values(WATCHLISTS).includes(this.state.tickerListView) && "There are currently no tickers added to this watchlist"}
                        {Object.values(SCREENERS).includes(this.state.tickerListView) && "There are currently no tickers fulfilling this criteria"}
                    </div>
                </div>
            )
            }
            {
                this.state.rows != null && this.state.loading != true && this.state.selectedSymbols.length > 0 && 
                <Row>
                    <Col style={{textAlign : "right", paddingTop: "1rem"}}>
                        { this.state.tickerListView != WATCHLISTS.MYWATCHLIST ? 
                            <Button variant="contained" className="watchlist-action" onClick={async() => {
                                this.setState({loading : true})
                                await this.api.AddToWatchlist(this.state.selectedSymbols)
                                await this.loadWatchlist()
                                this.setState({selectedSymbols: []})
                            }}>Save to Watchlist</Button>:
                            <Button  variant="contained" color="error" className="watchlist-action" onClick={async() => {
                                this.setState({loading : true})
                                await this.api.RemoveFromWatchlist(this.state.selectedSymbols)
                                await this.loadWatchlist()
                                this.setState({selectedSymbols: []})
                            }}>Remove from Watchlist</Button> 
                        }
                    </Col>
                </Row>
            }
            </>
        )
    }
}
export default TickerTableComponent;