import config from '../config/config'

class AccessController {


    //TEACHERS PORTAL
    UserCanAddProspect(accessLevels) {
        if ((accessLevels instanceof Array)) {
            return (accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo))
        } 
        return false
    }

    UserCanAddTeacher(accessLevels) {
        if (!(accessLevels instanceof Array)) {
            return false
        }
        if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo))
        {
            return true;
        }else{
            return false;
        }
    }

    UserCanEditTeacher(accessLevels) {
        if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo))
        {
            return true;
        }else{
            return false;
        }
    }
    
    UserCanResetCredentials(accessLevels) {
        if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo))
        {
            return true;
        }else{
            return false;
        }
    }

    UserCanEditTeacherCentre(accesslevels){
        if(accesslevels.includes(config.accessLevelOne))
        {
            return true;
        }else{
            return false;
        }
    }

    UserCanViewTeachersCurriculumEnrolled(accessLevels) {
        if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo))
        {
            return true;
        }else{
            return false;
        }
    }

    //STUDENTS PORTAL
    UserCanAddFamilyAndStudent(accessLevels) {
        if ((accessLevels instanceof Array)) {
            return (accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo))
        } 
        return false
    }

    UserCanEditStudent(accessLevels) {
        if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo)){
            return true;
        }else{
            return false;
        }
    }

    UserCanAddClassAndUploadMedia(accessLevels) {
        if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo) || accessLevels.includes(config.accessLevelThree)){
            return true;
        }else{
            return false;
        }
    }

    UserCanAddNewCreditsPurchased(accessLevels) {
        if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo)){
            return true;
        }else{
            return false;
        }
    }

    UserCanSetCredit(accessLevels) {
        if(accessLevels.includes(config.accessLevelOne)){
            return true;
        }else{
            return false;
        }
    }

    UserCanChecklistAndNotes(accessLevels) {
        if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo) || accessLevels.includes(config.accessLevelThree)){
            return true;
        }else{
            return false;
        }
    }

    UserCanViewStudentCurriculumEnrolled(accessLevels) {
        if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo) || accessLevels.includes(config.accessLevelThree)){
            return true;
        }else{
            return false;
        }
    }

    UserCanViewStudentCurriculumMilestoneAndCompletion(accessLevels) {
        if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo) || accessLevels.includes(config.accessLevelThree)){
            return true;
        }else{
            return false;
        }
    }

    UserCanEditCreditTransaction(accessLevels){
        if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo) || accessLevels.includes(config.accessLevelThree)){
            return true;
        }else{
            return false;
        }
    }

    UserCanDeleteCreditTransaction(accessLevels){
        if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo)){
            return true;
        }else{
            return false;
        }
    }

    UserCanEditTransactionPackages(accessLevels, relatedClass){
        if(relatedClass == null){
            if(accessLevels.includes(config.accessLevelOne) || accessLevels.includes(config.accessLevelTwo)){
                return true;
            }else{
                return false;
            }
        }
        else{
            return true
        }
    }

    // TEACHER AND STUDENT PORTAL
    UserCanRemoveCurriculum(accessLevels) {
        if ((accessLevels instanceof Array)) {
            return accessLevels.includes(config.accessLevelOne)
        } 
        return false
    }

}

export default AccessController;
