
import React from 'react';
import { Row, Col } from 'react-bootstrap'

import TrendData from '../../../../Models/TrendData.js'
import GaugeComponent from '../GaugeComponent/GaugeComponent.js'

class TrendComponent extends React.Component { 
    constructor(props,context) {
        super(props,context)
        this.api = new TrendData()
        this.state = {
            trendscore: {
                trend: 0
            },
            atr: {
                atr: 0
            },
            zscore: {
                zscore: 0
            },
        }
    }

    componentDidMount() {
        this.initData(0)
    }

    async initData(retry) {
        if (retry > 5) {
            return
        }
        let [trendscore, atr, zscore] = await Promise.all([
            this.api.GetTrendScore(),
            this.api.GetATR(),
            this.api.GetZScore(),
        ]).catch(err => {
            retry += 1
            this.initData(retry)
        })
        
        
        this.setState({
            trendscore: this.unpackResponse(trendscore, this.state.trendscore),
            atr: this.unpackResponse(atr, this.state.atr),
            zscore: this.unpackResponse(zscore, this.state.zscore),
        })
    }
    
    unpackResponse(res, def) {
        if (res && res.success) {
            return res.value
        } else {
            return def
        }
    }

    render() {
        return (
            <div
                style={{ 
                    backgroundColor: '#343434', 
                    borderRadius: "1em",
                    padding: "1em",
                    height: "100%",
                    fontWeight: "bold"
                }}
            >
                 <Row>
                    <Col style={{textAlign: "center", fontWeight: "bold", fontSize: "1.5rem", marginBottom: "1rem"}}>
                        SPY
                    </Col>
                </Row>
                <Row style={{fontSize: "1.2rem"}}>
                    <Col>
                        Trend
                        <GaugeComponent max={3} min={-3} data={this.state.trendscore.trend}/>
                        <div style={{marginTop: "-20px"}}>{this.state.trendscore.trend}</div>
                    </Col>
                    <Col>
                        Z-score
                        <GaugeComponent max={4} min={-4} data={this.state.zscore.zscore}/>
                        <div style={{marginTop: "-20px"}}>{parseFloat(this.state.zscore.zscore).toFixed(1)}</div>
                    </Col>
                    <Col>
                        ATR
                        <GaugeComponent max={4} min={-4} data={this.state.atr.atr}/>
                        <div style={{marginTop: "-20px"}}>{parseFloat(this.state.atr.atr).toFixed(1)}</div>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default TrendComponent;