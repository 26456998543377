import Model from './Model'
class UserAccount {
    constructor() {
        this.model = new Model()
    }
    GetUserAccount(accountId, callback) {
        this.model.get(`/account/${accountId}`, null, (res, err) => {
            if (err !== null) {
                callback([], err)
            } else {
                callback(res.value)
            }
        })
    }

    async ChangePassword(username, oldPW, newPW) {
        try {
            let resp = await this.model.post(`/password/change`, {
                username, oldPW, newPW
            })
            return resp.success
        } catch (err) {
            return false
        }
    }

    RequestPasswordReset(username, callback) {
        this.model.postReq(`/password/request_reset`, { username: username }, (res, err) => {
            if (err !== null || !res.success) {
                callback([], err || res.error)
            } else {
                callback(res.success)
            }
        })
    }

    ResetPassword(newPassword, token, callback) {
        this.model.postReq(`/password/reset`, { newPassword: newPassword, token: token }, (res, err) => {
            if (err !== null || !res.success) {
                callback([], err || res.error)
            } else {
                callback(res.value)
            }
        })
    }
}
export default UserAccount;