import axios from "axios"
class RSS {
    async GetFeed(rssUrl) {
        try {
            const response = await axios.get(rssUrl, {withCredentials: false})
            return response.data
        } catch (err) {
            return null
        }
    }
}
export default RSS;