import React from "react";
import { withRouter } from "../../Helpers/WithRouter";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import './footer.css'
class Footer extends React.Component {
  constructor(props, context) {
    super(props, context)

  }
  render() {
    return (
      <MDBFooter className="footer">
        <MDBContainer fluid className="text-center text-md-left">
          <MDBRow className="footerRow" style={{ display: "flex" }}>
            <MDBCol className="footerLeftColumn" style={{ fontSize: "1.2rem" }}>
              <p>© Tao Of Trading Ltd. All rights reserved.</p>
            </MDBCol>
            <MDBCol className="footerRightColumn" style={{ textAlign: "right" }}>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBFooter>
    );
  }
}

export default withRouter(Footer);