
import React from 'react';
import { Row, Col } from 'react-bootstrap'

import TrendData from '../../../../Models/TrendData.js'
import GaugeComponent from '../GaugeComponent/GaugeComponent.js'
import { Spinner } from 'react-activity';

class SentimentComponent extends React.Component { 
    constructor(props,context) {
        super(props,context)
        this.api = new TrendData()
        this.state = {
            sentiment: null
        }
    }

    componentDidMount() {
        this.initData(0)
    }

    async initData(retry) {
        if (retry > 5) {
            return
        }
        let resp = await this.api.GetSentiment()
        this.setState({
            sentiment: resp.value
        })
    }

    render() {
        return (
            <div
                style={{ 
                    backgroundColor: '#343434', 
                    borderRadius: "1em",
                    padding: "1em",
                    height: "100%",
                    fontWeight: "bold"
                }}
            >
                {this.state.sentiment == null ? 
                <Spinner style={{
                    margin: "auto",
                }}/> :
                <>
                <Row>
                    <Col style={{textAlign: "center", fontWeight: "bold", fontSize: "1.5rem", marginBottom: "1rem"}}>
                        Market Sentiment
                    </Col>
                </Row>
                <Row style={{fontSize: "1.2rem"}}>
                    <Col>
                        Risk On/Off
                        <GaugeComponent max={9} min={0} data={this.state.sentiment.risk}/>
                        <div style={{marginTop: "-20px"}}>{this.state.sentiment.risk.toFixed(1)}</div>
                    </Col>
                    <Col>
                        Put/Call Ratio
                        <GaugeComponent max={2} min={0} data={this.state.sentiment.putcall}/>
                        <div style={{marginTop: "-20px"}}>{this.state.sentiment.putcall.toFixed(2)}</div>
                    </Col>
                    <Col>
                        Volatility
                        <GaugeComponent max={100} min={0} data={this.state.sentiment.volatility}/>
                        <div style={{marginTop: "-20px"}}>{this.state.sentiment.volatility.toFixed(1)}</div>
                    </Col>
                </Row>
                </>}
            </div>
        )
    }
}
export default SentimentComponent;