import React from "react";
import { Modal } from "react-bootstrap"
// props :
// show boolean
// onCloseModal function
class FullScreenModal extends React.Component { 
    constructor(props,context) {
        super(props,context) 
        this.state = {
            showModal : props.show
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.show !== prevProps.show) {
          this.setState({showModal : this.props.show})
        }
    }
    renderModal() {
        return (
            <Modal size="lg" centered={true} show={this.state.showModal} onHide={() => {
                this.props.onCloseModal()
            }}>
                {this.props.children}
            </Modal>
        )
    }
    render() {
        if (this.state.showModal) {
            return (
                <div style={{position : "fixed", top : 0, left: 0, width: "100vw", height: " 100vh", backgroundColor : "rgba(255,255,255,0.3)", zIndex : 98}}>
                    <div style={{position : "absolute", top : "30%", left: "20%", right : "20%", zIndex : 99}} >
                        {this.renderModal()}    
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
       
    }
}
export default FullScreenModal;
